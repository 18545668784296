@charset "UTF-8";
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

*, *::after, *::before {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'campagne';
    src: url('../font/campagne.svg?16994094#campagne') format('svg');
  }
}
*/
@font-face {
  font-family: 'Excelsior LT Std';
  src: url("../fonts/ExcelsiorLTStd-Italic.woff2") format("woff2"), url("../fonts/ExcelsiorLTStd-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Excelsior LT Std';
  src: url("../fonts/ExcelsiorLTStd.woff2") format("woff2"), url("../fonts/ExcelsiorLTStd.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Excelsior LT Std';
  src: url("../fonts/ExcelsiorLTStd-Bold.woff2") format("woff2"), url("../fonts/ExcelsiorLTStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "campagne";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-search:before {
  content: '\e800'; }

/* '' */
.icon-mail:before {
  content: '\e801'; }

/* '' */
.icon-carret-right:before {
  content: '\e802'; }

/* '' */
.icon-carret-left:before {
  content: '\e803'; }

/* '' */
.icon-cancel-circle:before {
  content: '\e805'; }

/* '' */
.icon-checkbox:before {
  content: '\e819'; }

/* '' */
.icon-globe:before {
  content: '\e81a'; }

/* '' */
.icon-social:before {
  content: '\e81b'; }

/* '' */
.icon-facebook:before {
  content: '\f052'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-youtube-play:before {
  content: '\f16a'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
.icon-pinterest:before {
  content: '\f231'; }

/* '' */
.icon-twitter-circled:before {
  content: '\f30a'; }

/* '' */
.icon-gplus-circled:before {
  content: '\f310'; }

/* '' */
/*animations*/
/******************
* Bounce in right *
*******************/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.slow {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.slower {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.slowest {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

/* Added by Andy Meetan */
.delay-250 {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s; }

.delay-500 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.delay-750 {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s; }

.delay-1000 {
  -webkit-animation-delay: 1.0s;
  animation-delay: 1.0s; }

.delay-1250 {
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s; }

.delay-1500 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s; }

.delay-1750 {
  -webkit-animation-delay: 1.75s;
  animation-delay: 1.75s; }

.delay-2000 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s; }

.delay-2500 {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s; }

.delay-2000 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s; }

.delay-2500 {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s; }

.delay-3000 {
  -webkit-animation-delay: 3.0s;
  animation-delay: 3.0s; }

.delay-3500 {
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s; }

.bounceInRight, .bounceInLeft, .bounceInUp, .bounceInDown {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.fadeInRight, .fadeInLeft, .fadeInUp, .fadeInDown {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.flipInX, .flipInY, .rotateIn, .rotateInUpLeft, .rotateInUpRight, .rotateInDownLeft, .rotateDownUpRight, .rollIn {
  opacity: 0; }

.lightSpeedInRight, .lightSpeedInLeft {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

/***********
* bounceIn *
************/
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.bounceIn.go {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

/****************
* bounceInRight *
****************/
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px); }
  60% {
    -webkit-transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px);
            transform: translateX(400px); }
  60% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.bounceInRight.go {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

/******************
* Bounce in left *
*******************/
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px); }
  60% {
    -webkit-transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px); }
  60% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.bounceInLeft.go {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

/******************
* Bounce in up *
*******************/
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px); }
  60% {
    -webkit-transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px); }
  60% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.bounceInUp.go {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

/******************
* Bounce in down *
*******************/
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px); }
  60% {
    -webkit-transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px);
            transform: translateY(-400px); }
  60% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.bounceInDown.go {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

/**********
* Fade In *
**********/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    display: block; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    display: block; } }

.fadeIn {
  opacity: 0; }

.fadeIn.go {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

/**********
* Grow in *
***********/
@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 0; }
  50% {
    -webkit-transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }

@keyframes growIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0; }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.growIn {
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  opacity: 0; }

.growIn.go {
  -webkit-animation-name: growIn;
  animation-name: growIn; }

/********
* Shake *
********/
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); } }

.shake.go {
  -webkit-animation-name: shake;
  animation-name: shake; }

/********
* ShakeUp *
********/
@-webkit-keyframes shakeUp {
  0%, 100% {
    -webkit-transform: translateY(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateY(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateY(10px); } }

@keyframes shakeUp {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px); } }

.shakeUp.go {
  -webkit-animation-name: shakeUp;
  animation-name: shakeUp; }

/*************
* FadeInLeft *
*************/
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.fadeInLeft {
  opacity: 0;
  -webkit-transform: translateX(-400px);
  transform: translateX(-400px); }

.fadeInLeft.go {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

/*************
* FadeInRight *
*************/
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px);
            transform: translateX(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.fadeInRight {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.fadeInRight.go {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

/*************
* FadeInUp *
*************/
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.fadeInUp {
  opacity: 0;
  -webkit-transform: translateY(400px);
  transform: translateY(400px); }

.fadeInUp.go {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

/*************
* FadeInDown *
*************/
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px);
            transform: translateY(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.fadeInDown {
  opacity: 0;
  -webkit-transform: translateY(-400px);
  transform: translateY(-400px); }

.fadeInDown.go {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

/*****************
* rotateIn *
*****************/
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: rotate(-200deg);
            transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; } }

.rotateIn.go {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

/*****************
* rotateInUpLeft *
*****************/
@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; } }

.rotateInUpLeft.go {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

/*******************
* rotateInDownLeft *
*******************/
@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; } }

.rotateInDownLeft.go {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

/******************
* rotateInUpRight *
*******************/
@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; } }

.rotateInUpRight.go {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

/********************
* rotateInDownRight *
********************/
@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; } }

.rotateInDownRight.go {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

/*********
* rollIn *
**********/
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
            transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
            transform: translateX(0px) rotate(0deg); } }

.rollIn.go {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/*********
* wiggle *
**********/
@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg); }
  10% {
    -webkit-transform: skewX(-8deg); }
  20% {
    -webkit-transform: skewX(7deg); }
  30% {
    -webkit-transform: skewX(-6deg); }
  40% {
    -webkit-transform: skewX(5deg); }
  50% {
    -webkit-transform: skewX(-4deg); }
  60% {
    -webkit-transform: skewX(3deg); }
  70% {
    -webkit-transform: skewX(-2deg); }
  80% {
    -webkit-transform: skewX(1deg); }
  90% {
    -webkit-transform: skewX(0deg); }
  100% {
    -webkit-transform: skewX(0deg); } }

@keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
            transform: skewX(9deg); }
  10% {
    -webkit-transform: skewX(-8deg);
            transform: skewX(-8deg); }
  20% {
    -webkit-transform: skewX(7deg);
            transform: skewX(7deg); }
  30% {
    -webkit-transform: skewX(-6deg);
            transform: skewX(-6deg); }
  40% {
    -webkit-transform: skewX(5deg);
            transform: skewX(5deg); }
  50% {
    -webkit-transform: skewX(-4deg);
            transform: skewX(-4deg); }
  60% {
    -webkit-transform: skewX(3deg);
            transform: skewX(3deg); }
  70% {
    -webkit-transform: skewX(-2deg);
            transform: skewX(-2deg); }
  80% {
    -webkit-transform: skewX(1deg);
            transform: skewX(1deg); }
  90% {
    -webkit-transform: skewX(0deg);
            transform: skewX(0deg); }
  100% {
    -webkit-transform: skewX(0deg);
            transform: skewX(0deg); } }

.wiggle.go {
  -webkit-animation-name: wiggle;
  animation-name: wiggle;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/********
* swing *
*********/
@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.swing.go {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

/*******
* tada *
********/
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
            transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
            transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
            transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0); } }

.tada.go {
  -webkit-animation-name: tada;
  animation-name: tada; }

/*********
* wobble *
**********/
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%); } }

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
            transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
            transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
            transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
            transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
            transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

.wobble.go {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

/********
* pulse *
*********/
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.pulse.go {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

/***************
* lightSpeedInRight *
****************/
@-webkit-keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

@keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
            transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
            transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
            transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
            transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

.lightSpeedInRight.go {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

/***************
* lightSpeedInLeft *
****************/
@-webkit-keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translateX(-100%) skewX(30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(20%) skewX(-30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

@keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translateX(-100%) skewX(30deg);
            transform: translateX(-100%) skewX(30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(20%) skewX(-30deg);
            transform: translateX(20%) skewX(-30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(15deg);
            transform: translateX(0%) skewX(15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
            transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

.lightSpeedInLeft.go {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

/*******
* Flip *
*******/
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
            transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
            transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
            transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
            transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
            transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; } }

.flip.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip; }

/**********
* flipInX *
**********/
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
            transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
            transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
            transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
            transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

.flipInX.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX; }

/**********
* flipInY *
**********/
@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
            transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
            transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
            transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
            transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

.flipInY.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY; }

/*****************
* Out animations *
*****************/
/************
* bounceOut *
*************/
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3); } }

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3); } }

.bounceOut.goAway {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

/************
* bounceOutUp *
*************/
@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
            transform: translateY(-2000px); } }

.bounceOutUp.goAway {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

/************
* bounceOutDown *
*************/
@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
            transform: translateY(2000px); } }

.bounceOutDown.goAway {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

/************
* bounceOutLeft *
*************/
@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
            transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
            transform: translateX(-2000px); } }

.bounceOutLeft.goAway {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

/************
* bounceOutRight *
*************/
@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
            transform: translateX(2000px); } }

.bounceOutRight.goAway {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

/************
* fadeOut *
*************/
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut.goAway {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

/************
* fadeOutUp *
*************/
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
            transform: translateY(-2000px); } }

.fadeOutUp.goAway {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

/************
* fadeOutDown *
*************/
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
            transform: translateY(2000px); } }

.fadeOutDown.goAway {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

/************
* fadeOutLeft *
*************/
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
            transform: translateX(-2000px); } }

.fadeOutLeft.goAway {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

/************
* fadeOutRight *
*************/
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
            transform: translateX(2000px); } }

.fadeOutRight.goAway {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

/************
* flipOutX *
*************/
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
            transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
            transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

.flipOutX.goAway {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important; }

/************
* flipOutY *
*************/
@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
            transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
            transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY; }

/************
* lightSpeedOutRight *
*************/
@-webkit-keyframes lightSpeedOutRight {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

@keyframes lightSpeedOutRight {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
            transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
            transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

.lightSpeedOutRight.goAway {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/************
* lightSpeedOutLeft *
*************/
@-webkit-keyframes lightSpeedOutLeft {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100%) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOutLeft {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
            transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100%) skewX(30deg);
            transform: translateX(-100%) skewX(30deg);
    opacity: 0; } }

.lightSpeedOutLeft.goAway {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/************
* rotateOut *
*************/
@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
    opacity: 0; } }

.rotateOut.goAway {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

/************
* rotateOutUpLeft *
*************/
@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; }
  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0; } }

.rotateOutUpLeft.goAway {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

/************
* rotateOutDownLeft *
*************/
@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0; } }

.rotateOutDownLeft.goAway {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

/************
* rotateOutUpRight *
*************/
@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0; } }

.rotateOutUpRight.goAway {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

/************
* rollOut *
*************/
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
            transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
            transform: translateX(100%) rotate(120deg); } }

.rollOut.goAway {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

/*****************
* Short Animations
*******************/
/*********************
* fadeInUpShort
*********************/
@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.fadeInUpShort {
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px); }

.fadeInUpShort.go {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

/*********************
* fadeInDownShort
*********************/
@-webkit-keyframes fadeInDownShort {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInDownShort {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.fadeInDownShort {
  opacity: 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px); }

.fadeInDownShort.go {
  -webkit-animation-name: fadeInDownShort;
  animation-name: fadeInDownShort; }

/*********************
* fadeInRightShort 
*********************/
@-webkit-keyframes fadeInRightShort {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInRightShort {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
            transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.fadeInRightShort {
  opacity: 0;
  -webkit-transform: translateX(20px);
  transform: translateX(20px); }

.fadeInRightShort.go {
  -webkit-animation-name: fadeInRightShort;
  animation-name: fadeInRightShort; }

/*********************
* fadeInLeftShort 
*********************/
@-webkit-keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.fadeInLeftShort {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px); }

.fadeInLeftShort.go {
  -webkit-animation-name: fadeInLeftShort;
  animation-name: fadeInLeftShort; }

/* ========================================================================== *\
   Base
   \* ========================================================================== */
*, *:after, *:before {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

html, body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #121313; }

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-x: hidden; }

body {
  color: #1e1e1e;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.7;
  min-width: 320px;
  margin: 0; }

.main {
  background-image: var(--image);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0 22.5px; }
  @media screen and (min-width: 1024px) {
    .main {
      background-position: top center;
      background-size: contain; } }

.container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 0 22.5px; }
  .container::after {
    clear: both;
    content: "";
    display: block; }

a {
  color: #505050;
  -webkit-transition: color 0.25s ease;
  transition: color 0.25s ease; }
  a:hover, a:focus, a:active {
    color: #aaa; }

section ul {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-position: outside; }
  section ul li {
    position: relative; }

body {
  display: table;
  table-layout: fixed;
  width: 100%; }

.wrap {
  display: table-row;
  height: 100%; }

.footer {
  display: table-row;
  height: 1px; }

p {
  padding-bottom: em(15px);
  margin: 0; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Excelsior LT Std", sans-serif;
  padding-bottom: em(15px);
  margin: 0;
  font-weight: normal;
  line-height: 1.15; }

h1, .h1 {
  font-size: calc(30px + 35 * ( (100vw - 300px) / 1150)); }
  @media screen and (max-width: 300px) {
    h1, .h1 {
      font-size: 30px; } }
  @media screen and (min-width: 1450px) {
    h1, .h1 {
      font-size: 65px; } }

h2, .h2 {
  font-size: calc(30px + 24 * ( (100vw - 300px) / 1150));
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: -2px;
  color: #e0da8d; }
  @media screen and (max-width: 300px) {
    h2, .h2 {
      font-size: 30px; } }
  @media screen and (min-width: 1450px) {
    h2, .h2 {
      font-size: 54px; } }
  h2 em, .h2 em {
    font-weight: normal;
    font-style: italic; }

h3, .h3 {
  font-size: calc(24px + 12 * ( (100vw - 320px) / 680)); }
  @media screen and (max-width: 320px) {
    h3, .h3 {
      font-size: 24px; } }
  @media screen and (min-width: 1000px) {
    h3, .h3 {
      font-size: 36px; } }

h4, .h4 {
  font-size: 18px; }

h5, .h5 {
  font-size: 16px; }

h6, .h6 {
  font-size: 14px; }

.button {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 25px;
  margin-top: 25px;
  background-color: #3a3a3a;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  position: relative;
  text-transform: uppercase; }
  .button:hover {
    color: white;
    background-color: #4bbded; }
    .button:hover i {
      color: white; }
  .button i {
    color: #4bbded;
    margin-left: 10px; }

.header__menu--mobile {
  display: block;
  height: 0;
  overflow: hidden;
  z-index: 3;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  .open-nav .header__menu--mobile {
    height: 100%; }
  .header__menu--mobile .nav {
    list-style: none;
    padding: 0 25px;
    padding-top: 10%; }
    .header__menu--mobile .nav .menu-item {
      display: block;
      text-align: center; }
      @media screen and (min-width: 1024px) {
        .header__menu--mobile .nav .menu-item {
          text-align: right; } }
      .header__menu--mobile .nav .menu-item a {
        font-size: 38px;
        font-weight: 700;
        color: #e0da8d;
        text-decoration: none; }
      .header__menu--mobile .nav .menu-item.active a, .header__menu--mobile .nav .menu-item:hover a {
        color: white; }
  .header__menu--mobile span {
    width: 100%;
    text-align: right;
    display: block;
    color: white;
    margin-top: 50px;
    font-size: 25px;
    position: relative; }
  .header__menu--mobile .language {
    padding: 0 25px;
    text-align: center;
    font-size: 16px;
    color: #e0da8d; }
    @media screen and (min-width: 1024px) {
      .header__menu--mobile .language {
        text-align: right; } }
    .header__menu--mobile .language a {
      font-size: 16px;
      font-weight: 700;
      color: #e0da8d;
      text-decoration: none; }
      .header__menu--mobile .language a.active, .header__menu--mobile .language a:hover {
        color: white; }

#nav-trigger {
  -webkit-transition: opacity;
  transition: opacity;
  cursor: pointer;
  display: inline-block;
  height: 45px;
  position: absolute;
  top: 0;
  right: 25px;
  width: 36px;
  z-index: 4;
  /*----------  Hamburger icon  ----------*/
  /* Animate the icon when open/close */ }
  #nav-trigger .nav-trigger-text {
    font-size: 11px;
    font-weight: 500;
    color: white;
    position: absolute;
    right: 40px;
    top: 15px; }
  @media screen and (min-width: 1024px) {
    #nav-trigger {
      position: relative;
      top: auto;
      right: auto; } }
  #nav-trigger span {
    border-radius: 2px;
    background-color: #e0da8d;
    display: block;
    height: 2px;
    margin: 23px auto 0;
    position: relative;
    -webkit-transition: background-color 250ms;
    transition: background-color 250ms;
    width: 23px; }
    .scrolled #nav-trigger span {
      background-color: #e0da8d; }
    #nav-trigger span:before, #nav-trigger span:after {
      background-color: #e0da8d;
      border-radius: 0;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      -webkit-transition: top 250ms 500ms ease, background-color 250ms, -webkit-transform 250ms ease;
      transition: top 250ms 500ms ease, background-color 250ms, -webkit-transform 250ms ease;
      transition: top 250ms 500ms ease, transform 250ms ease, background-color 250ms;
      transition: top 250ms 500ms ease, transform 250ms ease, background-color 250ms, -webkit-transform 250ms ease;
      width: 28px; }
      .scrolled #nav-trigger span:before, .scrolled #nav-trigger span:after {
        background-color: #e0da8d; }
    #nav-trigger span:before {
      top: -8px; }
    #nav-trigger span:after {
      top: 8px; }
  .open-nav #nav-trigger span {
    width: 23px;
    background-color: transparent; }
    .open-nav #nav-trigger span:before {
      background-color: #e0da8d;
      top: 0;
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
              transform: rotate3d(0, 0, 1, 45deg);
      -webkit-transition: top 250ms ease, background-color 250ms, -webkit-transform 250ms 500ms ease;
      transition: top 250ms ease, background-color 250ms, -webkit-transform 250ms 500ms ease;
      transition: top 250ms ease, transform 250ms 500ms ease, background-color 250ms;
      transition: top 250ms ease, transform 250ms 500ms ease, background-color 250ms, -webkit-transform 250ms 500ms ease; }
    .open-nav #nav-trigger span:after {
      background-color: #e0da8d;
      top: 0;
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
              transform: rotate3d(0, 0, 1, -45deg);
      -webkit-transition: top 250ms ease, background-color 250ms, -webkit-transform 250ms 500ms ease;
      transition: top 250ms ease, background-color 250ms, -webkit-transform 250ms 500ms ease;
      transition: top 250ms ease, transform 250ms 500ms ease, background-color 250ms;
      transition: top 250ms ease, transform 250ms 500ms ease, background-color 250ms, -webkit-transform 250ms 500ms ease; }

header {
  z-index: 10;
  position: absolute;
  top: 0;
  padding-top: 10px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  width: 100%; }
  header .flex-wrapper {
    padding: 0 25px; }
    @media screen and (min-width: 1024px) {
      header .flex-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
  header .phone_number {
    color: white;
    font-size: 14px;
    font-weight: 300;
    text-align: center; }
    @media screen and (min-width: 1024px) {
      header .phone_number {
        text-align: right; } }
    header .phone_number .fa {
      color: #e0da8d;
      margin-right: 10px; }
    header .phone_number--desktop {
      display: none; }
      @media screen and (min-width: 1024px) {
        header .phone_number--desktop {
          display: block; } }
    @media screen and (min-width: 1024px) {
      header .phone_number--mobile {
        display: none; } }
  header .brand img {
    width: 145px;
    margin-top: 5px; }
    @media screen and (min-width: 1024px) {
      header .brand img {
        width: 250px;
        margin-left: -110px;
        margin-top: 0; } }
  @media screen and (min-width: 1024px) {
    header {
      padding-top: 50px; } }
  .stickyNav header {
    position: fixed;
    padding-top: 10px; }

.labels {
  padding: 20px 0; }
  @media screen and (min-width: 1024px) {
    .labels {
      padding: 50px 0; } }
  .labels .labels__container {
    float: left;
    display: block;
    margin-right: 1.86335%;
    width: 83.02277%;
    margin-left: 8.48861%; }
    .labels .labels__container:last-child {
      margin-right: 0; }
    .labels .labels__container__label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding: 10px;
      float: left;
      display: block;
      margin-right: 1.86335%;
      width: 49.06832%; }
      .labels .labels__container__label:last-child {
        margin-right: 0; }
      .labels .labels__container__label:nth-child(2n) {
        margin-right: 0; }
      .labels .labels__container__label:nth-child(2n+1) {
        clear: left; }
      @media screen and (min-width: 1024px) {
        .labels .labels__container__label {
          float: left;
          display: block;
          margin-right: 1.86335%;
          width: 23.60248%; }
          .labels .labels__container__label:nth-child(2n) {
            margin-right: 1.86335%; }
          .labels .labels__container__label:nth-child(2n+1) {
            clear: none; }
          .labels .labels__container__label:last-child {
            margin-right: 0; } }
      .labels .labels__container__label img {
        max-width: 100%; }
      .labels .labels__container__label span {
        font-size: 14px;
        font-weight: 700;
        display: block;
        text-align: center;
        color: white; }

footer {
  background: white; }
  footer .footer_container {
    padding: 30px;
    background: white url(../images/footer.jpg) 80% 50px no-repeat;
    background-size: 50%; }
    @media screen and (min-width: 1024px) {
      footer .footer_container {
        padding: 50px 0 240px;
        background: white url(../images/footer.jpg) 30% bottom no-repeat;
        background-size: 50%; } }
  footer .footer__title {
    margin-bottom: 100px;
    color: #e0da8d; }
    @media screen and (min-width: 1024px) {
      footer .footer__title {
        position: relative;
        top: 50px;
        text-align: right;
        float: left;
        display: block;
        margin-right: 1.86335%;
        width: 36.3354%;
        margin-left: 4.24431%; }
        footer .footer__title:last-child {
          margin-right: 0; } }
  footer .footer__contact {
    padding: 30px;
    color: white;
    background: #e0da8d; }
    @media screen and (min-width: 1024px) {
      footer .footer__contact {
        padding: 80px 50px 80px 90px; } }
    @media screen and (min-width: 1024px) {
      footer .footer__contact {
        float: left;
        display: block;
        margin-right: 1.86335%;
        width: 49.06832%;
        margin-left: 8.48861%; }
        footer .footer__contact:last-child {
          margin-right: 0; } }
    footer .footer__contact h2 {
      display: block;
      color: white; }
    @media screen and (min-width: 1024px) {
      footer .footer__contact_left, footer .footer__contact_right {
        float: left;
        display: block;
        margin-right: 1.86335%;
        width: 49.06832%; }
        footer .footer__contact_left:last-child, footer .footer__contact_right:last-child {
          margin-right: 0; } }
    footer .footer__contact_left a, footer .footer__contact_right a {
      font-weight: 700;
      text-decoration: none; }
    footer .footer__contact_left img, footer .footer__contact_right img {
      position: relative;
      bottom: -7px;
      width: 32px;
      margin-right: 15px; }
    footer .footer__contact .route {
      display: block;
      text-decoration: none; }
      footer .footer__contact .route img {
        position: relative;
        bottom: -7px;
        width: 32px;
        margin-right: 20px; }
  footer .disclaimer {
    padding: 10px 0;
    background: #F4F4F4; }
    @media screen and (min-width: 1024px) {
      footer .disclaimer {
        padding: 30px;
        text-align: left; } }
    footer .disclaimer .container {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.3); }
      footer .disclaimer .container a {
        color: rgba(0, 0, 0, 0.3); }
        footer .disclaimer .container a:hover {
          color: #e0da8d; }
      footer .disclaimer .container span {
        margin: 0 10px; }
      footer .disclaimer .container .nounderline {
        text-decoration: none; }
      footer .disclaimer .container .volg {
        font-weight: bold;
        display: block;
        margin: 10px;
        text-align: center;
        text-decoration: none;
        color: black; }
        @media screen and (min-width: 1024px) {
          footer .disclaimer .container .volg {
            float: right;
            margin: 0;
            text-align: left; } }
        footer .disclaimer .container .volg .fab {
          margin-right: 15px;
          font-size: 20px;
          position: relative;
          bottom: -2px;
          color: #e0da8d; }
        footer .disclaimer .container .volg:hover {
          color: #e0da8d; }

.slick_nav {
  float: right; }
  .slick_nav .slick-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 52px;
    height: 52px;
    background: #373838;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    float: left;
    color: rgba(255, 255, 255, 0.2); }
    .slick_nav .slick-button:hover {
      color: #e0da8d;
      background: #212222;
      cursor: pointer; }

.video-background {
  background: #000;
  position: absolute;
  top: -14%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-size: cover; }

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }

.video {
  height: 85vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center; }
  .video__play {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease; }
    .video__play img {
      width: 72px;
      height: 72px; }
    .video__play:hover {
      background: white;
      border-radius: 50%; }
  .video__text {
    letter-spacing: -1.5px;
    color: white;
    font-weight: bold; }
    @media screen and (min-width: 1024px) {
      .video__text {
        max-width: 66%;
        margin: 0 auto; } }
    .video__text em {
      font-weight: normal;
      font-style: italic; }
  @media screen and (min-width: 1024px) {
    .video:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: 30%;
      width: 2px;
      background-image: repeating-linear-gradient(to right, #e0da8d 0%, #e0da8d 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to right, #e0da8d 0%, #e0da8d 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, #e0da8d 0%, #e0da8d 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, #e0da8d 0%, #e0da8d 50%, transparent 50%, transparent 100%);
      background-position: left top, left bottom, left top, right top;
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;
      height: 75px; }
    .video:after {
      content: '\f107';
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      left: 50%;
      bottom: 30%;
      width: 27px;
      margin-left: -13px;
      display: block;
      position: absolute;
      border-radius: 50px;
      color: #2a2b20;
      background: #e0da8d;
      min-height: 27px; } }

.intro {
  position: relative;
  z-index: 3; }
  .intro .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media screen and (min-width: 1024px) {
      .intro .container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
    .intro .container:after {
      content: '';
      width: calc(100% - 45px);
      height: 100px;
      background: white;
      position: absolute;
      bottom: -100px;
      left: 22.5px; }
  .intro__half {
    padding: 45px 22.5px 45px 22.5px;
    margin-left: -22.5px;
    margin-right: -22.5px;
    position: relative;
    background: white; }
    @media screen and (min-width: 1024px) {
      .intro__half {
        margin: 0; } }
    @media screen and (min-width: 1024px) {
      .intro__half {
        width: 50%;
        padding: 90px 70px 90px 90px; } }
    .intro__half.image {
      display: none;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (min-width: 1024px) {
        .intro__half.image {
          display: block; } }
    .intro__half .landing-why__text {
      display: none; }
      .intro__half .landing-why__text.mobile-show {
        display: block; }
      @media screen and (min-width: 1024px) {
        .intro__half .landing-why__text {
          display: block; } }
    .intro__half .button {
      float: right; }
      @media screen and (min-width: 1024px) {
        .intro__half .button {
          float: left; } }
  .intro h2 {
    color: #e0da8d; }
  .intro .white {
    background: white; }
  @media screen and (min-width: 1024px) {
    .intro .border:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 20%;
      width: 2px;
      background-image: repeating-linear-gradient(to right, #e0da8d 0%, #e0da8d 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to right, #e0da8d 0%, #e0da8d 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, #e0da8d 0%, #e0da8d 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, #e0da8d 0%, #e0da8d 50%, transparent 50%, transparent 100%);
      background-position: left top, left bottom, left top, right top;
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;
      height: 60%; } }

.kwaliteit {
  position: relative;
  z-index: 3; }
  .kwaliteit .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media screen and (min-width: 1024px) {
      .kwaliteit .container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        padding: 0 3%; } }
  .kwaliteit__half {
    padding: 45px 22.5px 45px 22.5px;
    margin-left: -22.5px;
    margin-right: -22.5px;
    position: relative;
    background: #e0da8d url("../images/bg_kwaliteit.png") center no-repeat;
    background-size: 140%; }
    @media screen and (min-width: 1024px) {
      .kwaliteit__half {
        margin: 0;
        text-align: right; } }
    @media screen and (min-width: 1024px) {
      .kwaliteit__half {
        width: 50%;
        padding: 90px 70px 90px 90px; } }
    .kwaliteit__half.image {
      display: none;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (min-width: 1024px) {
        .kwaliteit__half.image {
          display: block; } }
    .kwaliteit__half .landing-why__text {
      display: none; }
      .kwaliteit__half .landing-why__text.mobile-show {
        display: block; }
      @media screen and (min-width: 1024px) {
        .kwaliteit__half .landing-why__text {
          display: block; } }
    .kwaliteit__half .button {
      float: right; }
  .kwaliteit h2 {
    color: white; }

.text_header {
  height: 30vh;
  border-bottom: 20px solid white;
  padding-top: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center; }
  @media screen and (min-width: 1024px) {
    .text_header {
      height: 50vh;
      border-bottom: 0;
      padding-top: 120px; } }
  @media screen and (min-width: 1920px) {
    .text_header {
      height: 60vh; } }
  .text_header__title {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 21px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2.5px; }

.textpage {
  position: relative;
  z-index: 3; }
  .textpage .container {
    background: white; }
    @media screen and (min-width: 1024px) {
      .textpage .container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        padding: 45px; } }
  .textpage .bg_yellow {
    background: #e0da8d url("../images/bg_kwaliteit.png") center no-repeat;
    background-size: 140%; }
    .textpage .bg_yellow h2 {
      color: white !important; }
  .textpage__video {
    display: block; }
    .textpage__video_overlay {
      position: absolute;
      z-index: 20;
      top: 20px;
      height: calc( 100% - 40px);
      width: calc(100% - 40px);
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      @media screen and (min-width: 1024px) {
        .textpage__video_overlay {
          top: 45px;
          height: calc( 100% - 90px);
          width: calc(100% - 90px); } }
    .textpage__video img {
      max-width: 100%; }
    .textpage__video_title {
      display: none;
      color: white;
      font-weight: normal;
      font-style: italic; }
      @media screen and (min-width: 1024px) {
        .textpage__video_title {
          display: block; } }
    .textpage__video iframe {
      position: absolute;
      top: 20px;
      height: calc( 100% - 40px);
      width: calc(100% - 40px);
      text-align: center;
      z-index: -1; }
      @media screen and (min-width: 1024px) {
        .textpage__video iframe {
          top: 45px;
          height: calc( 100% - 90px);
          width: calc(100% - 90px); } }
  .textpage .half_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .textpage__half {
    padding: 20px;
    margin-left: -22.5px;
    margin-right: -22.5px;
    position: relative; }
    @media screen and (min-width: 1024px) {
      .textpage__half {
        padding: 50px; } }
    @media screen and (min-width: 1024px) {
      .textpage__half {
        margin: 0; } }
    @media screen and (min-width: 1024px) {
      .textpage__half {
        width: 50%; } }
    .textpage__half.image {
      display: none;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (min-width: 1024px) {
        .textpage__half.image {
          display: block;
          padding: 0;
          min-height: 538px; } }
    .textpage__half p {
      padding-bottom: 15px; }
    .textpage__half h2 {
      color: #e0da8d; }
    .textpage__half .button {
      float: right; }
      @media screen and (min-width: 1024px) {
        .textpage__half .button {
          float: left; } }
  .textpage .white {
    background: white; }
